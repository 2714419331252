import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  NgZone,
  OnInit,
  ViewChild
} from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { Subscription, interval, lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage implements OnInit {
  api: VgApiService;
  loading = false;
  status = '';
  apiKey: string;

  apiUrl = 'https://api.garagenoeffner.music-light.de';
  // apiUrl: string = 'http://localhost:10000';

  cameraImageUrl;

  cameraUrl = 'wolpo.music-light.de:1024';
  name0 = 'admin';
  password0 = 'jl14koeg';

  gates: any[] = [
    { number: 4, state: 'Zu', wifiStatus: 0 },
    { number: 1, state: 'Zu', bottom: true, wifiStatus: 0 },
    { number: 2, state: 'Zu', bottom: true, wifiStatus: 0 },
  ];

  currentDoor: any = {
    number: 1,
    state: 'Offen',
    wifiStatus: 0,
  };

  counter: any;
  counterSub: Subscription;

  videoUrl;

  @ViewChild('cameraImage', { static: false }) cameraImage: ElementRef;
  @HostListener('window:visibilitychange') change() {
    if (!document.hidden) {
      console.log('document.visible');
      // this.getImage();
      this.startCheck();
    }
  }

  constructor(
    private httpClient: HttpClient,
    private toastCtrl: ToastController,
    private changeDetector: ChangeDetectorRef,
    private ngZone: NgZone,
    private loadingCtrl: LoadingController
  ) {}

  onPlayerReady(api: VgApiService) {
    this.api = api;
    setTimeout(() => {
      api.play();
    }, 500);
  }

  async ngOnInit() {
    this.apiKey = localStorage.getItem('apiKey');
    this.startCheck();
    /*const cameraUrl = `http://${this.cameraUrl}/tmpfs/auto.jpg?usr=${this.name0}&pwd=${this.password0}`;
    this.cameraImageUrl = `https://garagenoeffner.music-light.de/assets/imageProxy.php?url=${encodeURIComponent(
      cameraUrl
    )}`;

    this.apiKey = await localStorage.getItem('apiKey');
    this.getImage();
    this.getCameraImage();

    this.startCheck();
    const data: any = await lastValueFrom(
      this.httpClient.post(`https://rtsp-proxy.music-light.de/start`, {
        uri: 'rtsp://wolpo.music-light.de:7447/i6UUljGCOWzIlKGB',
        alias: 'camera1',
      })
    );
    this.videoUrl = `https://rtsp-proxy.music-light.de${data.uri}`;
    console.log(data);*/
  }

  startCheck() {
    if (this.counterSub) {
      this.counterSub.unsubscribe();
    }

    if (!this.counter) {
      this.counter = interval(3000);
    }

    this.getStatus();

    this.counterSub = this.counter.subscribe((n) => {
      console.log('check');
      this.getStatus();
    });
  }

  getCameraImage() {
    this.ngZone.runOutsideAngular(async () => {
      setTimeout(() => {
        this.getImage();
      }, 500);
    });
  }

  getImage() {
    this.ngZone.runOutsideAngular(() => {
      const cameraElm = this.cameraImage.nativeElement;
      cameraElm.src = this.cameraImageUrl + '&' + new Date().getTime();
      this.changeDetector.detectChanges();
    });
  }

  async selectGate(gate) {
    console.log(gate);
    this.currentDoor = gate;
    const toast = await this.toastCtrl.create({
      message: `Tor ${gate.number} wurde ausgewählt`,
      position: 'top',
      duration: 1000,
      translucent: true,
    });
    toast.present();
  }

  async getStatus() {
    this.loading = true;

    try {
      const data: any = await lastValueFrom(
        this.httpClient.get(this.apiUrl + '/status?apiKey=' + this.apiKey)
      );

      if (data && data.data) {
        const gateOneIndex = this.gates.findIndex((gate) => {
          return gate.number == 1;
        });
        this.gates[gateOneIndex].wifiStatus = data.data[1].wifiStatus;
        this.gates[gateOneIndex].state = data.data[1].open
          ? 'Nicht vollständig geschlossen'
          : 'Geschlossen';

        const gateTwoIndex = this.gates.findIndex((gate) => {
          return gate.number == 2;
        });
        this.gates[gateTwoIndex].wifiStatus = data.data[2].wifiStatus;
        this.gates[gateTwoIndex].state = data.data[2].open
          ? 'Nicht vollständig geschlossen'
          : 'Geschlossen';

        const gateFourIndex = this.gates.findIndex((gate) => {
          return gate.number == 4;
        });
        this.gates[gateFourIndex].wifiStatus = data.data[4].wifiStatus;
        this.gates[gateFourIndex].state = data.data[4].open
          ? 'Nicht vollständig geschlossen'
          : 'Geschlossen';

        this.currentDoor = this.gates.find(
          (gate) => gate.number == this.currentDoor.number
        );
      }

      // this.changeDetector.detectChanges();
      this.loading = false;
    } catch {
      this.loading = false;
    }
  }

  async sendCommand(
    method: 'open' | 'close' | 'stop' | 'reboot',
    message,
    gateId
  ) {
    this.loading = true;
    const loadingElm = await this.loadingCtrl.create();
    loadingElm.present();

    try {
      await lastValueFrom(
        this.httpClient.get(
          `${this.apiUrl}/${method}?apiKey=${this.apiKey}&gateId=${gateId}`
        )
      );

      const toast = await this.toastCtrl.create({
        message,
        position: 'top',
        duration: 2000,
        translucent: true,
      });
      toast.present();
      this.loading = false;
      loadingElm.dismiss();
      this.changeDetector.detectChanges();
    } catch (error) {
      console.log(error);
      let errorMessage;
      if (error.error.data == 'socket not connected') {
        errorMessage = 'Fehler: Garagenöffner nicht verbunden';
      } else if (error.error.data == 'timeout') {
        errorMessage =
          'Fehler: Befehl konnte nicht an Garagenöffner gesendet werden';
      } else {
        errorMessage = 'Fehler. Grund konnte nicht ermittelt werden.';
      }

      const toast = await this.toastCtrl.create({
        message: errorMessage,
        position: 'top',
        duration: 2000,
      });
      toast.present();
      loadingElm.dismiss();
      this.loading = false;
    } finally {
      loadingElm.dismiss();
      this.loading = false;
    }
  }

  async open(gateId) {
    this.sendCommand('open', 'Öffnen Befehl gesendet!', gateId);
  }

  async close(gateId) {
    this.sendCommand('close', 'Schließen Befehl gesendet!', gateId);
  }

  async stop(gateId) {
    this.sendCommand('stop', 'Stop Befehl gesendet!', gateId);
  }

  async reboot(gateId) {
    this.sendCommand('reboot', 'Neustart Befehl gesendet!', gateId);
  }
}
