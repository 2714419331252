import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
  constructor(
    private navCtrl: NavController,
    private alertCtrl: AlertController,
    private http: HttpClient
  ) {}

  ngOnInit() {
    localStorage.removeItem('apiKey');
    this.presentAlertPrompt();
  }
  async presentAlertPrompt() {
    const alert = await this.alertCtrl.create({
      header: 'Passwort',
      message: 'Bitte geben Sie das Passwort ein',
      backdropDismiss: false,
      inputs: [
        {
          name: 'password',
          type: 'password',
          placeholder: 'Passwort',
        },
      ],
      buttons: [
        {
          text: 'Einloggen',
          handler: async (res) => {
            try {
              const body: any = await this.http
                .get(
                  'https://api.garagenoeffner.music-light.de/getApiKey/' +
                    res.password
                )
                .toPromise();
              console.log('setAPI KEy');
              await localStorage.setItem('apiKey', body.data.message);
              this.navCtrl.navigateRoot('/');
            } catch {
              console.log('catch');
              this.presentAlertPrompt();
            }

            console.log('Confirm Ok', res);
          },
        },
      ],
    });

    await alert.present();
  }
}
