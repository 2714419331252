<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title> Toröffner </ion-title>
    <ion-buttons slot="end" *ngIf="loading"> </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-card *ngIf="videoUrl">

   <!-- <vg-player>
      <video
        #myMedia
        id="my-video"
        [vgHls]="videoUrl"
        autoplay loop muted playsinline
      ></video>
    </vg-player>
    <img
      #cameraImage
      class="camera-image"
      (load)="getCameraImage()"
      (error)="getCameraImage()"
    />-->
  </ion-card>

  <ion-card>
    <div class="map">
      <div
        *ngFor="let gate of gates"
        class="gate"
        (click)="selectGate(gate)"
        [class.selected]="currentDoor.number == gate.number"
      >
        <div class="line"></div>
        <span [class.bottom]="gate.bottom" class="number">{{gate.number}}</span>
        <span [class.bottom]="gate.bottom" class="status">
          <ion-icon
            *ngIf="gate.state=='Geschlossen'"
            class="closed"
            name="lock-closed-outline"
          ></ion-icon>
          <ion-icon
            *ngIf="gate.state!='Geschlossen'"
            class="opened"
            name="lock-open-outline"
          ></ion-icon>
        </span>
      </div>
    </div>
  </ion-card>

  <ion-card>
    <div class="spinner" *ngIf="loading">
      <ion-spinner></ion-spinner>
    </div>
    <ion-card-header>
      <ion-card-subtitle>Tor</ion-card-subtitle>
      <ion-card-title>{{currentDoor.number}}</ion-card-title>
    </ion-card-header>

    <ion-item lines="none">
      <!-- <ion-icon slot="start" name="tablet-landscape-outline"></ion-icon>-->
      <ion-label>Status</ion-label>
      <ion-badge
        slot="end"
        [color]="currentDoor.state == 'Geschlossen' ? 'success' : 'danger'"
        >{{currentDoor.state }}</ion-badge
      >
    </ion-item>

    <ion-item lines="none">
      <ion-icon slot="start" name="wifi"></ion-icon>
      <ion-label>Wifi Signal</ion-label>
      <ion-badge slot="end">{{currentDoor.wifiStatus}} dB</ion-badge>
    </ion-item>

    <br />
    <ion-item button (click)="open(currentDoor.number)">
      <ion-icon name="arrow-up" slot="start"></ion-icon>
      <ion-label>Öffnen</ion-label>
    </ion-item>

    <ion-item button (click)="close(currentDoor.number)">
      <ion-icon name="arrow-down" slot="start"></ion-icon>
      <ion-label>Schließen</ion-label>
    </ion-item>

    <ion-item button lines="none" (click)="stop(currentDoor.number)">
      <ion-icon name="hand-left-outline" slot="start"></ion-icon>
      <ion-label>Stoppen</ion-label>
    </ion-item>

    <br />
    <ion-item button lines="none" (click)="reboot(currentDoor.number)">
      <ion-icon name="refresh-circle-outline" slot="start"></ion-icon>
      <ion-label>Neustarten</ion-label>
    </ion-item>
  </ion-card>
</ion-content>
<!--
<ion-footer> </ion-footer>
-->
