import { Component } from '@angular/core';
import { MenuController, NavController, ToastController } from '@ionic/angular';

declare var window: any;
@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: 'app.component.html',
})
export class AppComponent {
  public appPages = [
    {
      title: 'Toröffner',
      url: '/dashboard',
      icon: 'home',
    },
  ];

  constructor(
    private toastCtrl: ToastController,
    private navCtrl: NavController,
    public menuCtrl: MenuController
  ) {}

  logout() {
    this.menuCtrl.close();
    this.navCtrl.navigateRoot('login');
  }
  checkUpdate() {
    window.isUpdateAvailable.then(async (isAvailable) => {
      if (isAvailable) {
        const toast = await this.toastCtrl.create({
          message:
            'Neues Update verfügbar. Laden Sie die WebApp neu um die Änderungen zu sehen!',
          position: 'bottom',

          translucent: true,
        });
        toast.present();
      }
    });
  }
}
