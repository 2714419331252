<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content">
      <ion-content>
        <ion-list>
          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages">
            <ion-item
              [routerLinkActive]="['active']"
              [routerDirection]="'root'"
              [routerLink]="[p.url]"
            >
              <ion-icon slot="start" [name]="p.icon"></ion-icon>
              <ion-label>
                {{ p.title }}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
      <ion-footer>
        <ion-toolbar>
          <ion-list lines="none">
            <ion-item
              ><strong>Version</strong>
              <ion-badge slot="end">3.7.1</ion-badge>
            </ion-item>
            <ion-item (click)="logout()">
              <ion-icon slot="start" name="exit"></ion-icon>
              <ion-label> Ausloggen </ion-label>
            </ion-item>
          </ion-list>
        </ion-toolbar>
      </ion-footer>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
