import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(public router: Router) {}

  canActivate(): boolean {
    let apiKey = localStorage.getItem('apiKey');
    if (apiKey) {
      return true;
    }
    this.router.navigate(['/login']);
    return false;
  }
}
